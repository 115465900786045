.footer {
    background-color: #4F3747;
    position: relative;
  }
  
  .grundge2 {
    position: absolute;
    transform: rotateX(180deg);
    width: 100%;
    top: -4px;
  }
  
  .text-heading {
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .text-content a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
  }
  
  .text-content a span {
    margin-left: 31px;
  }
  
  .footer-shade {
    position: relative;
    background-color: #46313F;
    height: 80px;
    bottom: 0px;
  }
  
  /* Responsive Styling */
  @media screen and (max-width: 992px) {
    .footer-logo {
      position: relative;
      
    }
  
    .text-heading {
      font-size: 14px;
    }
  
    .text-content a {
      font-size: 11px;
    }
  
    .text-content a span {
      margin-left: 28px;
    }
  
    .quick-link {
      position: relative;
    }
  
    .contact {
      position: relative;
    }
  
    .social {
      position: relative;
   
    }
  }
  
  @media screen and (max-width: 500px) {
    .footer-logo {
      text-align: center;
    }
  
    .quick-link h6 {
      font-size: 15px;
    }
  
    .quick-link h6 a {
      font-size: 12px;
    }
  
    .contact {
      padding-left: 50px !important;
    }
  
    .contact h6 {
      position: relative;
      left: 12px;
      font-size: 15px;
    }
  
    .contact h6 a {
      font-size: 11px;
    }
  
    .social {
      text-align: center;
    }
  
    .social h6 {
      font-size: 15px;
      display: flex;
      justify-content: center;
      justify-content: space-around;
    }
  
    .social h6 a {
      margin-top: 10px;
    }
  }
  .footer {
    background-color: #4F3747;
    position: relative;
  }
  .grundge2 {
    position: absolute;
    transform: rotateX(180deg);
    width: 100%;
    top: -4px;
  }
  .text-heading {
    font-size: 16px;
    letter-spacing: 1px;
  }
  .text-content a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
  }
  .text-content a span {
    margin-left: 31px;
  }
  .footer-shade {
    position: relative;
    background-color: #46313F;
    height: 80px;
    bottom: 0px;
  }
  
  /* Add responsive styling as per your original code */
  @media screen and (max-width:1400px) {
    /* other styles */
  }
  @media screen and (max-width:1200px) {
    .footer-logo {
      position: relative;
      left: -25px;
    }
  }
  @media screen and (max-width:992px) and (min-width:768px)  {
    .footer-logo {
      position: relative;
      left: -40px;
    }
    .text-heading {
      font-size: 14px;
    }
    .text-content a {
      font-size: 11px;
    }
  }
  @media screen and (max-width:500px) {
    .footer-logo {
      text-align: center;
    }
    .quick-link h6 a {
      font-size: 12px;
    }
    .contact {
      padding-left: 50px !important;
    }
    .contact h6 a {
      font-size: 11px;
    }
    .social {
      text-align: center;
    }
  }
  @media screen and (max-width:406px) {
    .quick-link h6 a {
      font-size: 12px;
    }
    .contact {
      padding-left: 30px !important;
    }
    .contact h6 a {
      font-size: 11px;
    }
  }
  