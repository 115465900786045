.home-header {
  position: relative;
  overflow: hidden;
}
.grundge1 {
  position: absolute;
  bottom: -39px;
  left: 0px;
  width: 100%;
}
.popular-destinations img:hover {
  cursor: pointer;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
  scale: 1.01;
  animation: destinations 0.8s infinite alternate ease-in;
}
@keyframes destinations {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-6px);
  }
}
.popular-destinations-text {
  color: #4f3747;
  font-variant: small-caps;
  font-weight: 900;
  text-align: center;
}
.duration {
  width: 42.7%;
}
.duration-line {
  font-size: 13px;
}
.duration-line::after {
  content: "";
  width: 50%;
  height: 1px;
  position: absolute;
  top: 314px;
  left: 176px;
  background-color: #4f3747;
}
.trip-facts {
  display: flex;
  flex-direction: row;
}
.facilities-icon {
  display: block;
  border-radius: 50%;
  padding: 6px;
  border: 2px solid #4f3747;
}
.facilities-icon svg {
  background-color: transparent;
  color: #4f3747;
  height: 35px;
  width: 35px;
}
.facilities-text {
  font-size: 10px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
.card-img-top {
  height: 250px;
}
.flight-plan-icon {
  height: 80px;
}
.flight-plan-text {
  font-size: 12px;
  margin: 5px;
}
.cost {
  background-color: #4f3747;
}
.tour-cost h6 {
  color: white;
  font-size: 13px;
}
.tour-cost h6 span {
  font-size: 20px;
}
.tour-card-button a {
  text-decoration: none;
  color: #4f3747;
  border-radius: 20px;
}
.tour-card-button:hover a {
  color: white;
  border-color: #4f3747;
  transition: all 0.4s ease;
}
.tour-card-button {
  border-radius: 20px;
  background-color: white;
  color: #4f3747;
  font-size: 13px;
}
.tour-card-button:hover {
  background-color: #4f3747;
  color: white;
  border-color: #4f3747;
  transition: all 0.4s ease;
}
.content {
  margin: 2px;
}
.gallery-pictures img {
  border: 1px solid black;
  object-fit: cover;
  height: 160px;
  width: 230px;
}
.footer {
  background-color: #4f3747;
  position: relative;
}
.grundge2 {
  position: absolute;
  transform: rotateX(180deg);
  width: 100%;
  top: -4px;
}
.text-heading {
  font-size: 16px;
  letter-spacing: 1px;
}
.text-content a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
}
.text-content a span {
  margin-left: 31px;
}
.footer-shade {
  position: relative;
  background-color: #46313f;
  height: 80px;
  bottom: 0px;
}

@media screen and (max-width: 1400px) {
  .duration {
    width: 50.1% !important;
  }
  .duration-line::after {
    width: 40%;
  }
  .modal-dialog {
    max-width: 30% !important;
  }
}
@media screen and (max-width: 1200px) {
  .duration {
    width: 39% !important;
  }
  .duration-line::after {
    width: 40%;
    left: 180px;
  }
  .modal-dialog {
    max-width: 35% !important;
  }
  .footer-logo {
    position: relative;
    left: -25px;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .duration {
    width: 54% !important;
  }
  .duration-line::after {
    width: 40%;
    left: 178px;
  }
  .modal-dialog {
    max-width: 45% !important;
  }
  .gallery-pictures img {
    border: 1px solid black;
    object-fit: cover;
    height: 170px;
    width: 300px;
  }
  .footer-logo {
    position: relative;
    left: -40px;
  }
  .text-heading {
    font-size: 14px;
  }
  .text-content a {
    font-size: 11px;
  }
  .text-content a span {
    margin-left: 28px;
  }
  .quick-link {
    position: relative;
    left: 70px;
  }
  .contact {
    position: relative;
    left: 40px;
  }
  .social {
    position: relative;
    left: 24px;
  }
}
@media screen and (max-width: 500px) {
  .duration {
    width: 45% !important;
  }
  .duration-line::after {
    width: 40%;
    left: 180px;
  }
  .modal-dialog {
    position: absolute;
    top: 100px;
    left: 0px;
    max-width: 100% !important;
  }
  .popular-destinations-text {
    color: #4f3747;
    font-variant: small-caps;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
  }
  .footer-logo {
    text-align: center;
  }
  .quick-link h6 {
    font-size: 15px;
  }
  .quick-link h6 a {
    font-size: 12px;
  }
  .contact {
    padding-left: 50px !important;
  }
  .contact h6 {
    position: relative;
    left: 12px;
    font-size: 15px;
  }
  .contact h6 a {
    font-size: 11px;
  }
  .social {
    text-align: center;
  }
  .social h6 {
    font-size: 15px;
    display: flex;
    justify-content: center;
    justify-content: space-around;
  }
  .social h6 a {
    margin-top: 10px;
  }
}
@media screen and (max-width: 406px) {
  .duration {
    width: 50% !important;
  }
  .duration-line::after {
    width: 40%;
    left: 175px;
  }
  .quick-link h6 {
    font-size: 15px;
  }
  .quick-link h6 a {
    font-size: 12px;
  }
  .contact {
    padding-left: 30px !important;
  }
  .contact h6 {
    position: relative;
    left: 12px;
    font-size: 15px;
  }
  .contact h6 a {
    font-size: 11px;
  }
  .social {
    text-align: center;
  }
  .social h6 {
    font-size: 15px;
    display: flex;
    justify-content: center;
    justify-content: space-around;
  }
  .social h6 a {
    margin-top: 10px;
  }
  .modal-dialog {
    position: absolute;
    top: 100px;
    left: 1px;
    max-width: 100% !important;
  }
}
