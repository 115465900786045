
.before{
    background-color: rgb(203, 201, 201);
    opacity: 0.7;
    border: none !important;
    
}
  .before::after {
    content: '';
    
    
    /* background: url(/public/Assets/close.png); */
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
   
    left: 13px;
    top: 357px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: 80%;
  }

  /* hotel */
  
.hotel{
    background-color: rgb(203, 201, 201);
    opacity: 0.7;
    border: none !important;
    
}
  .hotel::after {
    content: '';
    
    
    /* background: url(/public/Assets/close.png); */
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
   
    left: 4.7em;
    top: 357px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: 80%;
  }


  /* explore */
  
.explore{
    background-color: rgb(203, 201, 201);
    opacity: 0.7;
    border: none !important;
    
}
  .explore::after {
    content: '';
    
    
    /* background: url(/public/Assets/close.png); */
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
   
    left: 8.5em;
    top: 357px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: 80%;
  }

  /* meals */
  .meals{
    background-color: rgb(203, 201, 201);
    opacity: 0.7;
    border: none !important;
    
}
  .meals::after {
    content: '';
    
    
    /* background: url(/public/Assets/close.png); */
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
   
    left: 12rem;
    top: 357px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: 80%;
  }



  /* visa */


  .visa{
    background-color: rgb(203, 201, 201);
    opacity: 0.7;
    border: none !important;
    
}
  .visa::after {
    content: '';
    
    
    /* background: url(/public/Assets/close.png); */
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
   
    left: 15.6rem;
    top: 357px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: 80%;
  }


  /* insurance */
  .insurance{
    background-color: rgb(203, 201, 201);
    opacity: 0.7;
    border: none !important;
    
}
  .insurance::after {
    content: '';
    
    
    /* background: url(/public/Assets/close.png); */
    position: absolute;
    z-index: 1;
    width: 10%;
    height: 10%;
   
    left: 19.2rem;
    top: 357px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: 80%;
  }



  /* WhatsAppButton.css */
.whatsapp-button {
  position: fixed; /* Fixed position */
  bottom: 20px; /* Distance from the bottom */
  right: 50px; /* Distance from the right */
  display: flex; /* Flexbox for icon and text */
  align-items: center; /* Center vertically */
  background-color: white; /* White background */
  padding: 10px 25px; /* Padding around the button */
  border-radius: 25px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  cursor: pointer; /* Pointer cursor */
  z-index: 1000; /* Ensure it stays above other content */
  transition: background-color 0.3s; /* Transition for hover effect */
}

.whatsapp-button:hover {
  background-color: #e6ffe6; /* Light green background on hover */
}

.whatsapp-icon {
  
  margin-right: 0; /* Space between icon and text */
  font-size: 20px; /* Icon size */
}
