@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@500&display=swap');
*{
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
}
html{
    overflow-y: scroll;
}

body.modal-open {
    padding-right: 0px !important; 
}


.navbar {
    transition: top 0.3s;
}
  
.navbar-hidden {
    top: -100px !important; 
    position: fixed !important;
    width: 100% !important;
    z-index: 1000 !important;
}



.custom-dropdown {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1000;
    margin-top: 10px; /* Adjust to control dropdown position */
}

.custom-dropdown{
    margin-top: -5px;
    border-radius: 3px;
}
.custom-dropdown ul {
    list-style: none;
    padding: 0px;
}

.custom-dropdown ul li {
    padding: 10px 40px 10px 0px;
}

.custom-dropdown ul li a {
    text-decoration: none;
    color: black;
    display: block;
}

.submenu{
    width: 200%;
    border-radius: 3px;
}

.custom-dropdown ul li a:hover {
    color: #4F3747;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .submenu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
}

.dropdown-submenu:hover .submenu {
    display: block;
}

.navbar-nav .nav-item.dropdown-custom:hover .custom-dropdown {
    display: block;
}

/* Ensure the dropdowns don't affect the navbar layout */
.navbar-nav .nav-item {
    position: relative;
}




.navbar-nav{
    font-size: 13px;
}
.nav-item input{
    font-size: 13px; 
    border-radius: 20px;
}
.navbtn{
    font-size: 13px !important; 
    border-radius: 20px !important; 
    margin-left: -35px !important; 
    color: white !important;
}
.modal-dialog{
    max-width: 25% !important;
}
.modal-header{
    border-bottom: none !important;
}
.quick-enquiry-btn{
    position: fixed;
    top: 50%;
    right: 0px;
    font-size: 18px !important;
    padding: 10px 15px 10px 15px !important;
    transform: rotate(270deg);
    transform-origin: bottom right;
    z-index: 1000;
    padding: 10px 20px;
    border-radius: 5px 0 0 5px;
}

.quick-enquiry-btn{
    background-color: #4F3747 !important;
    color: white !important;
    border-color: #4F3747;
}
.form-group input{
    font-size: 13px;
}
.form-group textarea{
    font-size: 13px;
}
.form-check-label{
    font-size: 13px;
}
.form-control:focus {
    border-color: lightgray !important;
    box-shadow: none !important;
}
.form-check-input:focus{
    border-color: lightgray !important;
    box-shadow: none !important;
}
.btn-close:focus{
    border: none;
    box-shadow: none !important;
}
.modal-btn{
    background-color: #46313F !important;
}
.destination-search{
    font-size: 13px !important; 
    border-radius: 20px !important;
}
.btn-warning{
    font-size: 13px !important; 
    border-radius: 20px !important; 
    margin-left: -35px !important; 
    color: white !important;
}

.text-head{
    font-size: 20px;
    letter-spacing: 1px;
    color: #4F3747;
}
.text-content{
    font-size: 12px;
    color: #4F3747;
}
.detail-header{
    position: relative;
    overflow: hidden;
    height: 50rem;
    /* object-fit: contain !important; */
}
.detail-top{
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;
    top: -105px;
    left: -15px;
    background-color: white;
    padding: 30px 35px;
}
.days-nights-text{
    background-color: #4F3747;
    padding: 12px 20px;
    border-radius: 15px;
    width: 200px;
}
.days-nights-text h6{
    font-size: 13px;
}
.text-duration{
    padding-top: 5px;
    color: white;
    font-size: 13px;
}


.accordion{
    position: relative;
    top: -100px;
    left: -15px;
    border-radius: 15px;
}
.accordion-header{
    font-size: 25px;
    font-weight: bold;
}
.accordion-button:focus{
    box-shadow: none !important;
    background-color: transparent !important;
}


.inclusions{
    border: 0.5px solid rgb(230, 230, 230);
    border-radius: 8px;
    position: relative;
    top: -100px;
    left: -15px;
}
.inc-list div{
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: green;
}
.exclusions{
    border: 0.5px solid rgb(230, 230, 230);
    border-radius: 8px;
    position: relative;
    top: -100px;
    left: -15px;
}
.exc-list div{
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: red;
}
.inc-list h6{
    font-size: 15px;
    width: 100%;
}
.exc-list h6{
    font-size: 15px;
    width: 100%;
}
.payment-policy h6{
    font-size: 15px;
    line-height: 25px;
}
.payment-policy{
    border: 0.5px solid rgb(230, 230, 230);
    border-radius: 8px;
    position: relative;
    top: -100px;
    left: 30px;
}
.cancellation-policy{
    border: 0.5px solid rgb(230, 230, 230);
    border-radius: 8px;
    position: relative;
    top: -50px;
    left: 30px;
}















.detail-card{
    position: relative;
    right: 40px;
    top: -57px;
}
.cost{
   
    background-color: #4F3747;
}
.tour-cost h6{
    color: white; 
    font-size: 13px;
}
.tour-cost h6 span{
    font-size: 20px;
}
.trip-facts{
    display: flex;
    flex-direction: row;
    /* border-bottom: 2px solid #958690; */
}
.facilities-icon{
    display: block;
    border-radius: 50%;
    padding: 6px;
    border: 2px solid #4F3747;
}
.facilities-icon svg{
    background-color: transparent; 
    color: #4F3747;
    height: 45px;
    width: 45px;
}
.facilities-text{
    font-size: 10px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
}
.tour-card-detail span img{
    height: 30px;
    width: 30px;
}
.tour-card-detail span h6{
    font-size: 13px;
    color: #7d6375 !important;
    
}
.tour-card-button a{
    font-size: 15px;
    text-decoration: none; 
    color: white; 
}
.tour-card-button{
    border-radius: 20px;
    background-color: white;
   
    font-size: 13px;
}
.tour-card-button a{
    color: #4F3747 !important;

}
.tour-card-button:hover{
    scale: 1.03;
    transition: all 0.4s ease;
}





.footer{
    background-color: #4F3747;
    position: relative;
}
.grundge2{
    position: absolute;
    transform: rotateX(180deg);
    width: 100%;
    top: -4px;
}
.text-heading{
    font-size: 16px;
    letter-spacing: 1px;
}
.text-content a{
    text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
}
.text-content a span{
    margin-left: 31px;
}
.footer-shade{
    position: relative;
    background-color: #46313F;
    height: 80px;
    bottom: 0px;
}


@media screen and (max-width:2000px) {
    .itenary{
        position: relative;
        top: -350px;
    }
    .payment-policy{
        width: 91%;
    }
    .modal-dialog{
        max-width: 30% !important;
    }
}
@media screen and (max-width:1200px) {
    .itenary{
        position: relative;
        top: 100px;
    }
    .detail-card{
        margin-left: 270px;
    }
    .payment-policy{
        width: 91%;
        padding: 25px;
    }
    .our-team-heading{
        font-size: 35px;
    }
    .our-team{
        top: 0px;
        left: 0px;
    }
    .our-team-text{
        text-align: start;
    }
    .footer-logo{
        position: relative;
        left: -25px;
    }
    .modal-dialog{
        max-width: 35% !important;
    }
}
@media screen and (max-width:1100px){
    .modal-dialog{
        max-width: 40% !important;
    }
}
@media screen and (max-width:992px) and (min-width:768px) {
    .itenary{
        position: relative;
        top: 100px;
    }
    .payment-policy{
        width: 88%;
        padding: 25px;
    }
    .detail-card{
        margin-left: 100px;
        position: relative;
        left: 0px;
    }
    .footer-logo{
        position: relative;
        left: -40px;
    }
    .text-heading{
        font-size: 14px;
    }
    .text-content a{
        font-size: 11px;
    }
    .text-content a span{
        margin-left: 28px;
    }
    .quick-link{
        position: relative;
        left: 70px;
    }
    .contact{
        position: relative;
        left: 40px;
    }
    .social{
        position: relative;
        left: 24px;
    }
    .modal-dialog{
        max-width: 45% !important;
    }
}
@media screen and (max-width:500px) { 
    .detail-top{
        position: relative;
        width: 99%;
        left: -46px;
        margin: 0px !important;
    }
    .tour-detail{
        margin-left: -20px;
    }
    .days-nights{
        margin-top: 10px;
        margin-left: -20px;
    }
    .detail-card{
        margin-left: 0px;
        position: relative;
        top: -120px;
        left: 0px;
    }
    .detail-top{
        display: flex !important;
        flex-direction: column;
    }
    .tour-cost h6{
        margin-left: -15px !important;
    }
    .facilities-icon{
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 0px 5px 0px 5px;
        height: 50px;
        width: 50px;
    }
    .facilities-icon svg{
        height: 35px;
        width: 35px;
        padding: 0px !important;
    }
    .itenary{
        position: relative;
        padding: 0px !important;
        left: -20px;
        top: 20px;
    }
    .accordion{
        width: 94%;
    }
    .inclusions{
        width: 94%;
    }
    .exclusions{
        width: 94%;
    }
    .head{
        font-size: 20px;
    }
    .accordion-body{
        font-size: 14px;
    }
    .inc-list h6{
        font-size: 14px;
        width: 95%;
    }
    .exc-list h6{
        font-size: 14px;
        width: 95%;
    }
    .payment-policy{
        width: 94%;
        padding: 25px;
    }
    .policy-content h6{
        font-size: 14px;
    }
    .quick-enquiry-btn{
        display: none !important;
    }
    .footer-logo{
        text-align: center;
    }
    .quick-link h6{
        font-size: 15px;
    }
    .quick-link h6 a{
        font-size: 12px;
    }
    .contact{
        padding-left: 50px !important;
    }
    .contact h6{
        position: relative;
        left: 12px;
        font-size: 15px;
    }
    .contact h6 a{
        font-size: 11px;
    }
    .social{
        text-align: center;
    }
    .social h6{
        font-size: 15px;
        display: flex;
        justify-content: center;
        justify-content: space-around;
    }
    .social h6 a{
        margin-top: 10px;
    }
    .modal-dialog{
        position: absolute;
        top: 100px;
        left: 0px;
        max-width: 100% !important;
    }
}
@media screen and (max-width:406px) {
    .quick-link h6{
        font-size: 15px;
    }
    .quick-link h6 a{
        font-size: 12px;
    }
    .contact{
        padding-left: 30px !important;
    }
    .contact h6{
        position: relative;
        left: 12px;
        font-size: 15px;
    }
    .contact h6 a{
        font-size: 11px;
    }
    .social{
        text-align: center;
    }
    .social h6{
        font-size: 15px;
        display: flex;
        justify-content: center;
        justify-content: space-around;
    }
    .social h6 a{
        margin-top: 10px;
    }
    .modal-dialog{
        position: absolute;
        top: 100px;
        left: 1px;
        max-width: 100% !important;
    }
}