* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}


/* General Styles */
.team-section {
  background: transparent;
  padding: 80px 0;
  color: black;
}

.team-heading {
  font-size: 3rem;
  margin-bottom: 20px;
}

.team-intro {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

/* Team Cards */
.team-cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.team-card {
  position: relative;
  width: 280px;
  height: 350px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.team-card:hover {
  transform: translateY(-10px);
}

.team-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.team-card:hover .team-img {
  transform: scale(1.1);
}

/* Overlay Effect */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 0.3s ease;
}

.team-card:hover .overlay {
  opacity: 1;
}

.overlay h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
}

.overlay p {
  font-size: 1rem;
  color: #dcdcdc;
}
