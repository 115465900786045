@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@500&display=swap');
*{
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
}
html{
    overflow-y: scroll;
}


.navbar {
    transition: top 0.3s;
}
  
.navbar-hidden {
  top: -100px !important; 
  position: fixed !important;
  width: 100% !important;
  z-index: 1000 !important;
}

.custom-dropdown {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1000;
    margin-top: 10px; /* Adjust to control dropdown position */
}

.custom-dropdown{
    margin-top: -5px;
    border-radius: 3px;
}
.custom-dropdown ul {
    list-style: none;
    padding: 0px;
}

.custom-dropdown ul li {
    padding: 10px 40px 10px 0px;
}

.custom-dropdown ul li a {
    text-decoration: none;
    color: black;
    display: block;
}

.submenu{
    width: 200%;
    border-radius: 3px;
}

.custom-dropdown ul li a:hover {
    color: #4F3747;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .submenu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
}

.dropdown-submenu:hover .submenu {
    display: block;
}

.navbar-nav .nav-item.dropdown-custom:hover .custom-dropdown {
    display: block;
}

/* Ensure the dropdowns don't affect the navbar layout */
.navbar-nav .nav-item {
    position: relative;
}

body.modal-open {
    padding-right: 0px !important; 
}
.navbar-nav{
    font-size: 13px;
}
.nav-item input{
    font-size: 13px; 
    border-radius: 20px;
}
.navbtn{
    font-size: 13px !important; 
    border-radius: 20px !important; 
    margin-left: -35px !important; 
    color: white !important;
}
.modal-dialog{
    max-width: 25% !important;
}
.modal-header{
    border-bottom: none !important;
}
.quick-enquiry-btn{
    position: fixed;
    top: 50%;
    right: 0px;
    font-size: 18px !important;
    padding: 10px 15px 10px 15px !important;
    transform: rotate(270deg);
    transform-origin: bottom right;
    z-index: 1000;
    padding: 10px 20px;
    border-radius: 5px 0 0 5px;
}

.quick-enquiry-btn{
    background-color: #4F3747 !important;
    color: white !important;
    border-color: #4F3747;
}
.form-group input{
    font-size: 13px;
}
.form-group textarea{
    font-size: 13px;
}
.form-check-label{
    font-size: 13px;
}
.form-control:focus {
    border-color: lightgray !important;
    box-shadow: none !important;
}
.form-check-input:focus{
    border-color: lightgray !important;
    box-shadow: none !important;
}
.btn-close:focus{
    border: none;
    box-shadow: none !important;
}
.modal-btn{
    background-color: #46313F !important;
}
.destination-search{
    font-size: 13px !important; 
    border-radius: 20px !important;
}
.btn-warning{
    font-size: 13px !important; 
    border-radius: 20px !important; 
    margin-left: -35px !important; 
    color: white !important;
}
.contact-header{
    position: relative;
    overflow: hidden;
}
/* .grundge1{
    position: absolute;
    bottom: -2px;
    left: 0px;
    width: 100%;
} */
.contact-us-page{
    align-items: center;
    justify-content: center;
    font-size: 15px;
} 
.contact-us-page p{
    width: 60%;
}
.footer{
    background-color: #4F3747;
    position: relative;
}
.grundge2{
    position: absolute;
    transform: rotateX(180deg);
    width: 100%;
    top: -4px;
}
.text-heading{
    font-size: 16px;
    letter-spacing: 1px;
}
.text-content a{
    text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
}
.text-content a span{
    margin-left: 31px;
}
.footer-shade{
    position: relative;
    background-color: #46313F;
    height: 80px;
    bottom: 0px;
}

@media screen and (max-width:1400px) {
    
    .modal-dialog{
        max-width: 30% !important;
    }
}
@media screen and (max-width:1200px) {

    .footer-logo{
        position: relative;
        left: -25px;
    }
    .modal-dialog{
        max-width: 35% !important;
    }
}
@media screen and (max-width:1100px){
    .modal-dialog{
        max-width: 40% !important;
    }
    .contact-us-page{
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
}
@media screen and (max-width:992px) and (min-width:768px) {
    .contact-us-page{
        align-items: center;
        justify-content: center;
        font-size: 14px;
    } 
    .contact-us-page p{
        width: 80%;
    }
    .footer-logo{
        position: relative;
        left: -40px;
    }
    .text-heading{
        font-size: 14px;
    }
    .text-content a{
        font-size: 11px;
    }
    .text-content a span{
        margin-left: 28px;
    }
    .quick-link{
        position: relative;
        left: 70px;
    }
    .contact{
        position: relative;
        left: 40px;
    }
    .social{
        position: relative;
        left: 24px;
    }
    .modal-dialog{
        max-width: 45% !important;
    }
}
@media screen and (max-width:500px) {
    .contact-us-page{
        align-items: center;
        justify-content: center;
        font-size: 12px;
    } 
    .contact-us-page p{
        width: 90%;
    }
    .footer-logo{
        text-align: center;
    }
    .quick-link h6{
        font-size: 15px;
    }
    .quick-link h6 a{
        font-size: 12px;
    }
    .contact{
        padding-left: 50px !important;
    }
    .contact h6{
        position: relative;
        left: 12px;
        font-size: 15px;
    }
    .contact h6 a{
        font-size: 11px;
    }
    .social{
        text-align: center;
    }
    .social h6{
        font-size: 15px;
        display: flex;
        justify-content: center;
        justify-content: space-around;
    }
    .social h6 a{
        margin-top: 10px;
    }
    .modal-dialog{
        position: absolute;
        top: 100px;
        left: 40px;
        max-width: 100% !important;
    }
}
@media screen and (max-width:406px) {
    .contact-us-page{
        align-items: center;
        justify-content: center;
        font-size: 12px;
    } 
    .contact-us-page p{
        width: 90%;
    }
    .quick-link h6{
        font-size: 15px;
    }
    .quick-link h6 a{
        font-size: 12px;
    }
    .contact{
        padding-left: 30px !important;
    }
    .contact h6{
        position: relative;
        left: 12px;
        font-size: 15px;
    }
    .contact h6 a{
        font-size: 11px;
    }
    .social{
        text-align: center;
    }
    .social h6{
        font-size: 15px;
        display: flex;
        justify-content: center;
        justify-content: space-around;
    }
    .social h6 a{
        margin-top: 10px;
    }
    .modal-dialog{
        position: absolute;
        top: 100px;
        left: 12px;
        max-width: 100% !important;
    }
    
}