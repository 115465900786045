@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:wght@500&display=swap");

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}



.navbar {
  transition: top 0.3s;
}

.navbar-hidden {
  top: -100px !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 1000 !important;
  transition: top 0.3s ease-in-out;
}
.offcanvas-body .navbar-search {
  border-radius: 20px;
}
.offcanvas-body .dropdown-custom ul li {
  padding: 5px;
}

li a {
  text-decoration: none;
  color: #46313f;
}
/* Dropdown Styling */
.custom-dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1000;
  margin-top: 10px;
  border-radius: 3px;
  transition: visibility 0.3s, opacity 0.3s ease;
}

.navbar-nav .nav-item.dropdown-custom:hover .custom-dropdown,
.navbar-nav .nav-item.dropdown-custom .custom-dropdown:hover {
  visibility: visible;
  opacity: 1;
}

/* Submenu Styling */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .submenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  border-radius: 3px;
  transition: visibility 0.3s, opacity 0.3s ease;
  width: 200%;
}

.dropdown-submenu:hover .submenu,
.dropdown-submenu .submenu:hover {
  visibility: visible;
  opacity: 1;
}

.form-control:focus {
  border: none;
  box-shadow: none !important;
  outline: 1px solid #4f3747 !important; /* Replace #4f3747 with your preferred color */
}

.form-control {
  border: none;
  box-shadow: none !important;
  outline: 1px solid #4f3747 !important; /* Replace #4f3747 with your preferred color */
}

/* Additional Styling for Links */
.custom-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-dropdown ul li {
  padding: 10px 40px 10px 0;
  color: #46313f;
}

.custom-dropdown ul li a {
  text-decoration: none;
  color: black;
  display: block;
}

.custom-dropdown ul li a:hover {
  color: #4f3747;
}

/* General Navbar Styling */
.navbar-nav .nav-item {
  position: relative;
}

.navbar-nav {
  font-size: 13px;
}

.nav-item input,
.destination-search {
  font-size: 13px;
  border-radius: 20px;
}

.navbtn,
.btn-warning {
  font-size: 13px !important;
  border-radius: 20px !important;
  margin-left: -35px !important;
  color: white !important;
}

/* Modal and Form Styling */
.modal-dialog {
  max-width: 25% !important;
}

.modal-header {
  border-bottom: none !important;
}

.form-group input,
.form-group textarea {
  font-size: 13px;
}

.form-check-label {
  font-size: 13px;
}

.form-control:focus,
.form-check-input:focus,
.btn-close:focus {
  border: none;
  box-shadow: none !important;
}

.modal-btn {
  background-color: #46313f !important;
}

.quick-enquiry-btn{
  position: fixed;
  top: 50%;
  right: 0px;
  font-size: 18px !important;
  padding: 10px 15px 10px 15px !important;
  transform: rotate(270deg);
  transform-origin: bottom right;
  z-index: 1000;
  padding: 10px 20px;
  border-radius: 5px 0 0 5px;
}
.quick-enquiry-btn{
  background-color: #4F3747 !important;
  color: white !important;
  border-color: #4F3747;
}

/* Offcanvas Specific Styling */
.offcanvas {
  background-color: white;
}



.offcanvas .navbar-nav .nav-link {

  color: #46313f;
}

.offcanvas .navbar-nav .nav-link:hover {
  color: #4f3747;
}

.offcanvas .dropdown-custom .submenu {

 
  width: 300% !important;
  
}

.offcanvas .dropdown-custom .submenu a {
  font-size: 14px;
  color: #46313f;
  display: block;
  padding: 8px 16px;
}

.offcanvas .dropdown-custom .submenu a:hover {
  color: #4f3747;
}

.quick-enquiry-btn {
  bottom: 100px;
  right: 0px;
  z-index: 1000;
  height: 50px;
  width: 150px;
  border: none;
  outline: none;
  padding: 0%;
  text-decoration: none;
}

/* Responsive Styles */
@media screen and (max-width: 1440px) {
  .modal-dialog {
    max-width: 30% !important;
  }

  .navbar .offcanvas-nav .nav-link {
    font-size: 18px;
    color: #4f3747;
  }

  .offcanvas-body {
    padding: 1rem;
  }

}


@media screen and (max-width: 1200px) {
  .modal-dialog {
    max-width: 35% !important;
  }
}

@media screen and (max-width: 992px) {
  .modal-dialog {
    max-width: 45% !important;
  }
}

@media screen and (max-width: 500px) {
  .modal-dialog {
    max-width: 100% !important;
  }
}
